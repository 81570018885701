/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Col, Row, Spinner, SpinnerProps } from "reactstrap";

const Loader: React.FC<SpinnerProps> = (props) => (
  <Row className="flex-center py-5">
    <Col xs="auto">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Spinner {...props} />
    </Col>
  </Row>
);

Loader.defaultProps = {
  size: "lg",
  color: "primary",
};

export default Loader;
