/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useGet,
  UseGetReturn,
  useMutate,
  UseMutateReturn,
  MutateMethod,
} from "restful-react";

export type ProjectResponseData = {
  id: string;
  name: string;
  url: string;
  organization: string;
};

export type ProjectRequestBody = {
  name: string;
  url?: string;
};

interface ProjectsGetResponse extends UseGetReturn<any, any> {
  data: ProjectResponseData[] | null;
}

export const useProjects = (): ProjectsGetResponse =>
  useGet({ path: "projects/" });

interface ProjectGetResponse extends UseGetReturn<any, any> {
  data: ProjectResponseData;
}

export const useProject = (projectId: string): ProjectGetResponse =>
  useGet({ path: `projects/${projectId}/`, lazy: !projectId });

interface ProjectPostResponse extends UseMutateReturn<any, any, any, any, any> {
  mutate: MutateMethod<ProjectResponseData, ProjectRequestBody, any, any>;
}

export const useCreateProject = (): ProjectPostResponse =>
  useMutate({
    verb: "POST",
    path: "projects/",
  });

export const useUpdateProject = (projectId: string): ProjectPostResponse =>
  useMutate({
    verb: "PUT",
    path: `projects/${projectId}/`,
  });
