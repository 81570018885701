import React from "react";
import { useRouter } from "next/router";
import { useUser } from "../api/users";
import FullScreenLayout from "../layouts/FullScreenLayout";
import Loader from "../components/common/Loader";
import { useProjects } from "../api/projects";

const Root: React.FC = () => {
  const router = useRouter();
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading: isUserLoading,
  } = useUser();
  const { data: projects, loading: isProjectsLoading } = useProjects();
  const isLoading = isUserLoading || isProjectsLoading;

  if (isAuthenticated && !isLoading) {
    if (projects?.length) {
      router.push("/projects").catch(() => {});
    } else {
      router.push("/projects/create").catch(() => {});
    }
  }

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect().catch(() => {});
  }

  return (
    <FullScreenLayout>
      <Loader />
    </FullScreenLayout>
  );
};

export default Root;
