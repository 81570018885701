import React from "react";
import { Container, Col, Row } from "reactstrap";

const FullScreenLayout: React.FC = ({ children }) => (
  <Container className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
        {children}
      </Col>
    </Row>
  </Container>
);

export default FullScreenLayout;
